import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom, inject, NgModule, provideAppInitializer } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_ICON_DEFAULT_OPTIONS, MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideKeycloak } from 'keycloak-angular';
import { CountdownModule } from 'ngx-countdown';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { MatomoModule, MatomoRouterModule, MatomoTracker } from 'ngx-matomo-client';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';
import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ContextChangeComponent } from './components/context-change/context-change.component';
import { ExternalPortalIncluderComponent } from './components/external-portal-includer/external-portal-includer.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { CustomerdbService } from './components/services/customer-db/services/customer-db.service';
import { KeycloakBearerInterceptor } from './interceptors/keycloak-bearer.interceptor';
import { RoleCredentialInterceptor } from './interceptors/role-credential.interceptor';
import { ApiService } from './services/api.service';
import { AppService } from './services/app.service';
import { CacheFilterService } from './services/cache-filter.service';
import { DateService } from './services/date.service';
import { LoadingIndicatorService } from './services/loading-indicator.service';
import { MonacoService } from './services/monaco.service';
import { UserProfileService } from './services/userprofile.service';
import { SharedComponentsModule } from './shared/shared-components.module';

export const monacoConfig: NgxMonacoEditorConfig = {
	baseUrl: window.location.origin + '/assets/monaco/min/vs',
};

@NgModule({
	declarations: [AppComponent, ExternalPortalIncluderComponent, ContextChangeComponent, MaintenanceComponent],
	bootstrap: [AppComponent],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		CountdownModule,
		FlexLayoutModule,
		FormsModule,
		MatButtonModule,
		MatCardModule,
		MatChipsModule,
		MatDialogModule,
		MatDividerModule,
		MatIconModule,
		MatInputModule,
		MatMenuModule,
		MatomoModule.forRoot({
			trackerUrl: 'https://stats.cancom.io/',
			disabled: environment.trackingId < 0,
			siteId: environment.trackingId,
			scriptUrl: 'https://stats.cancom.io/bla.js',
			trackerUrlSuffix: 'bla.ccp',
		}),
		MatomoRouterModule,
		MatProgressSpinnerModule,
		MatSidenavModule,
		MatToolbarModule,
		MatTooltipModule,
		MonacoEditorModule.forRoot(),
		ReactiveFormsModule,
		SharedComponentsModule,
	],
	providers: [
		provideKeycloak({
			config: {
				url: environment.keycloak.url,
				realm: environment.keycloak.realm,
				clientId: environment.keycloak.clientId,
			},
			initOptions: {
				onLoad: 'check-sso',
				silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
			},
		}),
		provideAppInitializer(() => {
			inject(MatomoTracker).setRequestMethod('GET');
		}),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: KeycloakBearerInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RoleCredentialInterceptor,
			multi: true,
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {
				coreLibraryLoader: () => import('highlight.js/lib/core'),
				languages: {
					json: () => import('highlight.js/lib/languages/json'),
					ini: () => import('highlight.js/lib/languages/ini'),
				},
			},
		},
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: { appearance: 'fill' },
		},
		{
			provide: MAT_ICON_DEFAULT_OPTIONS,
			useValue: { fontSet: 'material-symbols-outlined' },
		},
		ApiService,
		AppService,
		CacheFilterService,
		CustomerdbService,
		DateService,
		LoadingIndicatorService,
		MonacoService,
		UserProfileService,
		provideHttpClient(withInterceptorsFromDi()),
		importProvidersFrom(MonacoEditorModule.forRoot(monacoConfig)),
	],
})
export class AppModule {}
