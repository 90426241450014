import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserService } from '../services/user.service';

@Injectable()
export class RoleCredentialInterceptor implements HttpInterceptor {
	constructor(private userService: UserService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const sessionToken = localStorage.getItem('impersonatedUser') || this.userService.getSession()?.jwt;

		if (sessionToken) {
			const authReq = request.clone({
				headers: request.headers.set('authorization', `Bearer ${sessionToken}`),
			});
			return next.handle(authReq);
		}

		return next.handle(request);
	}
}
