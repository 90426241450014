import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
	AllCommunityModule,
	ClientSideRowModelModule,
	CsvExportModule,
	InfiniteRowModelModule,
	ModuleRegistry,
	provideGlobalGridOptions,
} from 'ag-grid-community';
import {
	CellSelectionModule,
	ClipboardModule,
	ColumnMenuModule,
	ColumnsToolPanelModule,
	ContextMenuModule,
	FiltersToolPanelModule,
	GroupFilterModule,
	LicenseManager,
	MasterDetailModule,
	PivotModule,
	RichSelectModule,
	RowGroupingModule,
	RowGroupingPanelModule,
	ServerSideRowModelModule,
	SetFilterModule,
	SideBarModule,
	TreeDataModule,
	ViewportRowModelModule,
} from 'ag-grid-enterprise';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

ModuleRegistry.registerModules([
	AllCommunityModule,
	ClientSideRowModelModule,
	ClipboardModule,
	ColumnsToolPanelModule,
	CsvExportModule,
	FiltersToolPanelModule,
	InfiniteRowModelModule,
	MasterDetailModule,
	ColumnMenuModule,
	ContextMenuModule,
	CellSelectionModule,
	RichSelectModule,
	RowGroupingModule,
	RowGroupingPanelModule,
	GroupFilterModule,
	TreeDataModule,
	PivotModule,
	ServerSideRowModelModule,
	SetFilterModule,
	SideBarModule,
	ViewportRowModelModule,
]);

LicenseManager.setLicenseKey(environment.agGridLicenseKey);
provideGlobalGridOptions({ theme: 'legacy' });

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
