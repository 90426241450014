import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthGuardData, createAuthGuard } from 'keycloak-angular';

const isAccessAllowed = async (
	route: ActivatedRouteSnapshot,
	_: RouterStateSnapshot,
	authData: AuthGuardData
): Promise<boolean | UrlTree> => {
	const { authenticated, grantedRoles, keycloak } = authData;

	if (!authenticated) {
		keycloak.login();
		return false;
	} else {
		return true;
	}
};

export const AuthGuard = createAuthGuard<CanActivateFn>(isAccessAllowed);
