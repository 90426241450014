import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SERVICE_LOOKUP } from 'src/environments/global.config';

export interface ApiResponse<O> {
	data: O;
}

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	protected readonly api: string = environment.api.drive;

	constructor(protected readonly http: HttpClient) {
		const value = SERVICE_LOOKUP('drive');
		if (value != null && value.endpoint !== this.api) {
			this.api = value.endpoint;
		}
	}

	public get<ApiResponse>(path: string): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(this.api + path);
	}

	public getText(path: string): Observable<string> {
		return this.http.get(this.api + path, { responseType: 'text', observe: 'body' });
	}
}
