import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Keycloak from 'keycloak-js';
import { Observable } from 'rxjs';

import { UserService } from '../services/user.service';

/**
 * This interceptor includes the bearer by default in all HttpClient requests.
 *
 * If you need to exclude some URLs from adding the bearer, please, take a look
 * at the {@link KeycloakOptions} bearerExcludedUrls property.
 */
@Injectable()
export class KeycloakBearerInterceptor implements HttpInterceptor {
	constructor(
		private keycloak: Keycloak,
		private userService: UserService
	) {}

	/**
	 * Intercept implementation that checks if the request url matches the excludedUrls.
	 * If not, adds the Authorization header to the request if the user is logged in.
	 *
	 * @param req Request
	 * @param next HttpHandler
	 */
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Used for assumed role -> no Interception
		if (!!this.userService.getSession()) {
			return next.handle(req);
		}

		if (this.keycloak.authenticated) {
			const kcReq = req.clone({ headers: req.headers.set('authorization', `Bearer ${this.keycloak.token}`) });
			return next.handle(kcReq);
		} else {
			return next.handle(req);
		}
	}
}
